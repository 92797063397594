import React, { Component } from 'react';
import {CameraActions} from '../../../../actions';
import './stickerItem.scss';
import loadingSpinner from './images/loading.svg';

class StickerItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            load: false,
            data: {
                width: 0,
                height: 0,
                name: ''
            }
        }

        this.onImageLoad = this.onImageLoad.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    onImageLoad({target: img}) {
        this.setState({
            load: true,
            data: {
                ...this.state.data,
                width: img.width,
                height: img.height,
                url: this.props.src,
                name: this.props.src
            }
        });
    }

    onClick() {
        if(!this.state.load) return;
        CameraActions.addSticker(this.state.data);
        
        if(this.props.onClick) this.props.onClick(this.state.data);
    }

    render() {
        return (
            <div className={`sticker-item ${this.state.load === false ? 'loading' : ''}`}>
                <img src={loadingSpinner} className="loading-image" alt="loading"/>
                <img src={this.props.src} onLoad={this.onImageLoad} onClick={this.onClick} className="sticker-image" alt="sticker" />
            </div>
        )
    }
}

export {StickerItem}
const {REACT_APP_API_BASE_URL} = process.env;

export class EventService {
    constructor(shortenURL) {
        this.eventShortenUrl = shortenURL;
    }

    // defualt method for post request
    static GetDefualtMethod = {
        method: 'GET',
        headers: {
            'Content-Type':'application/json'
        },
        credentials: 'include',        
        mode: 'cors'
    }

    static getEventData(eventShortenUrl) {
        return fetch(`${REACT_APP_API_BASE_URL}/${eventShortenUrl}`, {
            ...EventService.GetDefualtMethod
        });
    }

    static postImage({eventShortenUrl, image, copies, onreadystatechange, onprogress}) {
        const formData = new FormData();
        formData.append('copies', copies);
        formData.append('image', image);

        const xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.open('POST', `${REACT_APP_API_BASE_URL}/${eventShortenUrl}`);

        xhr.onreadystatechange = onreadystatechange;

        xhr.upload.addEventListener('progress', onprogress);
        xhr.send(formData);
    }
}
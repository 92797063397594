var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import { Vector2, Color, TextureLoader, ClampToEdgeWrapping, LinearFilter, PlaneGeometry } from 'three';
import { getOrthographicCameraZoom, TransformObject } from '../utils';
import { Layer } from './';
import * as Debug from 'debug';
var debug = Debug('camera:ImageLayer');

var ImageLayer = function (_super) {
  __extends(ImageLayer, _super);

  function ImageLayer(dimensions, imageURL, rotationDegrees, controls) {
    var _this = _super.call(this, dimensions) || this;

    _this._sceneBgColor = 0xffffff;
    _this._imageNaturalDimensions = {
      width: null,
      height: null
    };
    _this._imageURL = imageURL;
    _this._rotationDegrees = rotationDegrees;
    _this._controls = controls;
    debug("image url: " + _this._imageURL);
    debug("image rotation degrees " + _this._rotationDegrees);

    _this.initCamera();

    _this.initTexture();

    _this.initMaterial(true);

    _this.initGeometry();

    _this.initMesh();

    _this.initScene();

    _this.scene.background = new Color(_this._sceneBgColor);
    return _this;
  }

  ImageLayer.prototype._update = function () {
    if (Math.abs(this._rotationDegrees) === 90) {
      var tmp = this._imageNaturalDimensions.width;
      this._imageNaturalDimensions.width = this._imageNaturalDimensions.height;
      this._imageNaturalDimensions.height = tmp;
    }

    if (this._rotationDegrees !== 0) {
      this.texture.center = new Vector2(0.5, 0.5);
      this.texture.rotation = -this._rotationDegrees * Math.PI / 180;
    }

    this.mesh.scale.x = this._imageNaturalDimensions.width;
    this.mesh.scale.y = this._imageNaturalDimensions.height;
    var zoom = getOrthographicCameraZoom(this._imageNaturalDimensions, {
      width: this.width,
      height: this.height
    });
    this.camera.zoom = zoom;

    this._controls.deleteLayer(ImageLayer._layerName);

    this._controls.addLayer({
      camera: this.camera,
      scene: this.scene,
      priority: ImageLayer._layerPriority,
      name: ImageLayer._layerName,
      transformObject: TransformObject.Camera,
      zoom: {
        min: zoom / 2,
        max: Infinity,
        start: 1
      },
      enableZoom: true,
      enableRotate: true,
      enableTranslation: true
    });

    this.camera.updateProjectionMatrix();
    debug("Camera zoom " + this.camera.zoom);
  };

  ImageLayer.prototype.initTexture = function () {
    var _this = this;

    this.texture = new TextureLoader().load(this._imageURL, function () {
      _this._imageNaturalDimensions.width = _this.texture.image.naturalWidth;
      _this._imageNaturalDimensions.height = _this.texture.image.naturalHeight;
      debug('natural image size %o', _this._imageNaturalDimensions);

      _this._update();
    });
    this.texture.wrapS = ClampToEdgeWrapping;
    this.texture.wrapT = ClampToEdgeWrapping;
    this.texture.minFilter = LinearFilter;
    this.texture.magFilter = LinearFilter;
  };

  ImageLayer.prototype.initGeometry = function () {
    this.geometry = new PlaneGeometry(1, 1);
  };

  ImageLayer.prototype.getImageNaturalDimensions = function () {
    return this._imageNaturalDimensions;
  };

  ImageLayer._layerName = 'image';
  ImageLayer._layerPriority = 1;
  return ImageLayer;
}(Layer);

export { ImageLayer };
import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {MainScreen} from '../UiElements';
import './welcome.scss';

class Welcome extends Component {
    constructor(props) {
        super(props);

        this.renderClientLogo = this.renderClientLogo.bind(this);
    }

    renderClientLogo() {
        const {logo} = this.props.event;
        if(!logo) return null;
        return (
            <div 
                className="client-logo" 
                style={{backgroundImage: `url(${logo})`}}>
            </div>
        )
    }

    render() {
        const {title} = this.props.event;
        return (
            <MainScreen headerElement={this.renderClientLogo()}>
                <div className="open-camera-wrapper">
                    <div className="event-title">
                        {title}
                    </div>
                    <Link to={`${this.props.match.url.replace(/\/$/g, '')}/cam`} className="open-camera">
                        <div></div>
                    </Link>
                </div>
            </MainScreen>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.eventReducer
    }
}

const WelcomeWithRedux = connect(mapStateToProps)(Welcome);

export {WelcomeWithRedux as Welcome}
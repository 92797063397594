import {eventConstants} from '../constants';

const eventReducer = (state = {}, action) => {
    switch(action.type) {
        case eventConstants.GET_EVENT_DATA:
            return {
                ...state,
                ...action.payload,
                errorEventNotFound: false
            }

        case eventConstants.ERROR_EVENT_NOT_FOUND:
            return {
                errorEventNotFound: true
            }

        default:
            return state;
    }
}

export default eventReducer;
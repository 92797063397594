import { Video, SelfieCamera, getImageRotationDegrees } from './utils';
import { Render } from './render';
import Events from './events';
import { DeviceCamera } from './deviceCamera';
import { UploadFromDevice } from './utils';
import * as Debug from 'debug';
var debug = Debug('camera:Camera');

var Camera = function () {
  function Camera(dimensions, canvas, selfieCamera, frameURL, trash, drawImageAfterDate) {
    this._dimensions = dimensions;
    this._canvas = canvas;
    this._frameURL = frameURL;
    this._selfieCamera = selfieCamera;
    this._trash = trash;
    this._drawImageAfterDate = drawImageAfterDate;
    if (!this._isWebGLAvailable()) return;

    this._initVideo();

    this._initDeviceCamera();

    this._initRender();
  }

  Camera.prototype._initVideo = function () {
    var _this = this;

    this._video = new Video();
    Events.on('videoLoadedMetaData', function () {
      debug('video on loaded meta data');

      _this._render.updateVideo(_this._selfieCamera);

      _this._video.play();

      if (!_this._render.isAnimate()) _this._render.animate();
    });
  };

  Camera.prototype._initRender = function () {
    this._render = new Render(this._dimensions, this._canvas, this._video, this._selfieCamera, this._frameURL, this._trash);
  };

  Camera.prototype._initDeviceCamera = function () {
    this._deviceCamera = new DeviceCamera();
  };

  Camera.prototype._closeCamera = function () {
    debug('close device camera');

    this._deviceCamera.close();
  };

  Camera.prototype._stopRender = function () {
    debug('stop render');

    this._render.stop();
  };

  Camera.prototype._isWebGLAvailable = function () {
    var available;

    try {
      var canvas = document.createElement('canvas');
      available = !!(window.WebGLRenderingContext && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl')));
    } catch (e) {
      available = false;
    }

    debug('WebGL %s', available ? 'Available' : 'Unavailable');
    available ? Events.dispatch('webglAvailable') : Events.dispatch('webglUnavailable');
    return available;
  };

  Camera.prototype.play = function () {
    this._deviceCamera.open(this._video, this._selfieCamera, {
      min: 480,
      ideal: 1280,
      max: 1920
    });

    if (!this._render.isAnimate()) this._render.animate();
  };

  Camera.prototype.pic = function () {
    debug('pic');

    this._video.pause();

    this._closeCamera();
  };

  Camera.prototype.flip = function () {
    if (this._selfieCamera === SelfieCamera.Environment) {
      this._selfieCamera = SelfieCamera.User;
    } else if (this._selfieCamera === SelfieCamera.User) {
      this._selfieCamera = SelfieCamera.Environment;
    }

    this.play();
  };

  Camera.prototype.uploadFromDevice = function (openDeviceCamera) {
    var _this = this;

    var uploadFromDevice = new UploadFromDevice(this._drawImageAfterDate);
    if (openDeviceCamera) uploadFromDevice.openCameraImmediately();
    return new Promise(function (resolve, reject) {
      uploadFromDevice.onUpload().then(function (file) {
        var blobURL = URL.createObjectURL(file);
        debug("upload from device blob url: " + blobURL);
        getImageRotationDegrees(file).then(function (deg) {
          _this._render.drawImage(blobURL, deg);

          _this._closeCamera();

          Events.dispatch('drawImageSuccess');
          resolve();
        });
      }).catch(function (e) {
        Events.dispatch('drawImageFailed');
        reject(e);
      });
      uploadFromDevice.open();
    });
  };

  Camera.prototype.getEffectsList = function () {
    return this._render.getPostProcessing().getShadersNamesList();
  };

  Camera.prototype.setEffect = function (effectName) {
    debug("Set effect: " + effectName);

    this._render.getPostProcessing().enableShader(effectName);
  };

  Camera.prototype.setBrightness = function (value) {
    this._render.getPostProcessing().setBrightness(value);
  };

  Camera.prototype.setContrast = function (value) {
    this._render.getPostProcessing().setContrast(value);
  };

  Camera.prototype.addSticker = function (stickerData) {
    this._render.addSticker(stickerData);
  };

  Camera.prototype.clearAllStickers = function () {
    this._render.clearAllStickers();
  };

  Camera.prototype.exportAsBase64 = function (mime) {
    if (mime === void 0) {
      mime = 'image/jpeg';
    }

    return this._canvas.toDataURL(mime);
  };

  Camera.prototype.stop = function () {
    return this._stopRender();
  };

  return Camera;
}();

export { Camera };
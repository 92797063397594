
export class StickerActions {
    static getStickersUrls({url, prefix, ex, start, end}) {
        url = url.replace(/\/$/g, '');
        const list = [];

        for(let i = start ; i <= end ; i++) {
            list.push(`${url}/${prefix}${i}.${ex}`);
        }

        return list;
    }
}
import { OrthographicCamera, MeshBasicMaterial, PlaneGeometry, Mesh, Scene } from 'three';

var Layer = function () {
  function Layer(dimensions) {
    this.width = dimensions.width;
    this.height = dimensions.height;
  }

  Layer.prototype.initCamera = function () {
    this.camera = new OrthographicCamera(this.width / -2, this.width / 2, this.height / 2, this.height / -2, 0.1, 1000);
    this.camera.position.z = 1;
  };

  Layer.prototype.initMaterial = function (transparent) {
    if (transparent === void 0) {
      transparent = false;
    }

    this.material = new MeshBasicMaterial({
      map: this.texture,
      transparent: transparent
    });
  };

  Layer.prototype.initGeometry = function () {
    this.geometry = new PlaneGeometry(this.width, this.height);
  };

  Layer.prototype.initMesh = function () {
    this.mesh = new Mesh(this.geometry, this.material);
  };

  Layer.prototype.initScene = function () {
    this.scene = new Scene();
    this.scene.add(this.mesh);
  };

  Layer.prototype.getCamera = function () {
    return this.camera;
  };

  Layer.prototype.getScene = function () {
    return this.scene;
  };

  Layer.prototype.dispose = function () {
    this.texture.dispose();
    this.material.dispose();
    this.geometry.dispose();
    this.scene.remove(this.mesh);
  };

  return Layer;
}();

export { Layer };
import React, { Component } from 'react';
import trashIcon from './images/trash-icon.png';
import './trash.scss';

class Trash extends Component {
    render() {
        return (
            <div id="camera-trash-btn" onClick={this.props.onClick}>
                <img src={trashIcon} alt="Trash" />
            </div>
        )
    }
}

export {Trash}
import { Vector2 } from 'three';
export var SobelShaderInvert = {
  uniforms: {
    "tDiffuse": {
      value: null
    },
    "resolution": {
      value: new Vector2(0, 0)
    }
  },
  vertexShader: "\n        varying vec2 vUv;\n\n        void main() {\n            vUv = uv;\n            gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0);\n        }\n    ",
  fragmentShader: "\n        uniform sampler2D tDiffuse;\n        varying vec2 vUv;\n        uniform vec2 resolution;\n\n        void main() {\n            float step_w = 1.0 / resolution.x;\n            float step_h = 1.0 / resolution.y;\n\n            vec2 offset[9];\n            offset[0] = vec2(-step_w, -step_h);\n            offset[1] = vec2(0.0, -step_h);\n            offset[2] = vec2(step_w, -step_h);\n            offset[3] = vec2(-step_w, 0.0);\n            offset[4] = vec2(0.0, 0.0);\n            offset[5] = vec2(step_w, 0.0);\n            offset[6] = vec2(-step_w, step_h);\n            offset[7] = vec2(0.0, step_h);\n            offset[8] = vec2(step_w, step_h);\n            \n            float sample[9];\n            \n            for( int i=0; i<9; i++ ) {\n                sample[i]= length(texture2D(tDiffuse, vUv + offset[i]).rgb);\n            }\n\n            float horizEdge = sample[0] + (2.0*sample[1]) + sample[2] - (sample[6] + (2.0*sample[7]) + sample[8]);\n            float vertEdge = sample[0] + (2.0*sample[3]) + sample[5] - (sample[2] + (2.0*sample[5]) + sample[8]);\n            \n            gl_FragColor.rgb = 1.0 - vec3(sqrt((horizEdge * horizEdge) + (vertEdge * vertEdge)));\n            gl_FragColor.a = 1.0;\n        }\n    "
};
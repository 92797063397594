import React, { Component } from 'react';
import {withTranslation} from 'react-i18next';

import './mainScreen.scss';
import selphoLogo from './images/selpho-logo.png';

class MainScreen extends Component {
    render() {
        const {t, id} = this.props;

        return (
            <div className="main-screen" id={id}>
                <div className="main-screen-wrapper">
                    <div className="main-screen-header">
                        {this.props.headerElement}
                        <a href="https://selpho.com">
                            <img src={selphoLogo} alt="SELPHO" className="selpho-logo" />
                        </a>
                    </div>
                    <div className="main-screen-body">
                        {this.props.children}
                    </div>
                    <div className="main-screen-footer">
                        <a href="../termsofuse.pdf" target="_blank" className="termsofuse">
                            {t('termsOfUse')}
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
const mainScreenWithT = withTranslation()(MainScreen);

export {mainScreenWithT as MainScreen}
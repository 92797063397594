import { combineReducers } from 'redux';
import eventReducer from './event';
import cameraReducer from './camera';
import uploadReducer from './upload';

const appRreducer = combineReducers({
    eventReducer,
    cameraReducer,
    uploadReducer
});

export default appRreducer;

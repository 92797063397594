import React, { Component } from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {PopupScreen} from '../UiElements';
import {cameraConstants} from '../../constants';
import errorIcon from './images/error-icon.png';

import './blocked.scss';

class BlockedPopup extends Component {
    onClose() {
        this.props.dispatch({type: cameraConstants.UPLOAD_IMAGE_REMOVE_BLOCKED});
    }

    render() {
        const {t, camera} = this.props;
        
        if(!camera || !camera.uploadImageBlocked) return null;

        return (
            <div id="blocked-popup">
                <PopupScreen showCloseButton={true} onClose={this.onClose.bind(this)}>
                    <div className="blocked-wrapper">
                        <img className="blocked-icon" src={errorIcon} alt="blocked"/>
                        <div className="blocked-title">
                            {t('blockedBigTitle')}
                        </div>
                        <div className="blocked-subtitle">
                            {t('blockedSubTitle')}
                        </div>
                    </div>
                </PopupScreen>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.eventReducer,
        camera: state.cameraReducer
    }
}

const BlockedWithRedux = connect(mapStateToProps)(BlockedPopup);
const BlockedWithT = withTranslation()(BlockedWithRedux);

export {BlockedWithT as BlockedPopup};
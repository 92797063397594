import React, { Component } from 'react';
import {CameraActions} from '../../../../actions';
import './filterList.scss';

class FilterList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentFilter: '',
            filters: []
        }

        this.getFilterList = this.getFilterList.bind(this);
    }

    componentDidMount() {
        this.getFilterList();
    }

    componentWillUnmount() {
        CameraActions.removeOnFilterListChage(this.getFilterList);
    }
    
    componentDidUpdate() {
        // fitst time (invok only ones)
        if(this.state.filters.length === 0) {
            CameraActions.onFilterListChage(this.getFilterList);
            this.getFilterList();
        }
    }
    
    getFilterList() {
        const filters = CameraActions.getFilterList();
        if(filters.length === 0 ) return;

        // '' is 'no-filter'
        this.setState({filters: ['', ...filters]});
    }
 
    setFilter(filter) {
        return () => {
            this.setState({currentFilter: filter});
            CameraActions.setFilter(filter);
        }
    }

    renderFilterList() {        
        return this.state.filters.map((item, i) => (
            <div 
                className={`filter-item ${item === this.state.currentFilter ? 'active': ''}`} 
                key={i} 
                onClick={this.setFilter(item)}>
                {item.substr(0, 2)}
            </div>
        ));
    }

    render() {
        if(!this.props.show) return null;

        return (
            <div id="camera-filter-list">
                <div className="filter-list-wrapper">
                    {this.renderFilterList()}
                </div>
            </div>
        )
    }
}

export {FilterList}
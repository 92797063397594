import { Raycaster, Vector3 } from 'three';
import Events from './events';
import { MatrixTransformation, TransformObject } from './utils';
import * as Debug from 'debug';
var debug = Debug('camera:Controls');

var Controls = function () {
  function Controls(canvas, dimensions) {
    this._layers = [];
    this._selectionOrderIndex = 1;
    this._canvas = canvas;
    this._width = dimensions.width;
    this._height = dimensions.height;
    this._canvasDimensionsRatio = {
      width: this._width / this._canvas.offsetWidth,
      height: this._height / this._canvas.offsetHeight
    };
    this._raycaster = new Raycaster();

    this._onMouseDown();

    this._onMouseUp();

    this._onTouchStart();

    this._onTouchEnd();

    this._onUpdateTransform();
  }

  Controls.prototype._intersectObjects = function (layer) {
    var intersects = this._raycaster.intersectObjects(layer.scene.children);

    intersects = intersects.filter(function (intersect) {
      return !(intersect.object.userData.uncontrollabel === true);
    });
    debug("Layer name: " + layer.name);
    debug('Raycaster intersects (without uncontrolabe objects) %o', intersects);

    if (intersects.length > 0) {
      this._selectedLayer = layer;
      var top_1 = intersects[0];

      for (var i = 1; i < intersects.length; i++) {
        if (intersects[i].object.renderOrder >= top_1.object.renderOrder) {
          top_1 = intersects[i];
        }
      }

      this._selectedObject = top_1.object;
      debug('Selected top %o', this._selectedObject);
      this._selectedObject.renderOrder = this._selectionOrderIndex++;
      return true;
    }

    return false;
  };

  Controls.prototype._initMatrixTransform = function () {
    if (!this._selectedLayer || !this._selectedObject || this._selectedObject.userData.transformation) return;
    var transformation = new MatrixTransformation('TSR', this._width / 2, this._height / 2, this._selectedLayer.zoom.start);
    this._selectedObject.userData.transformation = transformation;
    this._selectedObject.userData.prevTransform = transformation.cloneTransform();
  };

  Controls.prototype._normalizePosition = function (point) {
    var x = point.x,
        y = point.y;
    return {
      x: (x - this._canvas.offsetLeft + window.pageXOffset) / this._width * this._canvasDimensionsRatio.width * 2 - 1,
      y: -((y - this._canvas.offsetTop + window.pageYOffset) / this._height * this._canvasDimensionsRatio.height) * 2 + 1
    };
  };

  Controls.prototype._relativePosition = function (point) {
    var x = point.x,
        y = point.y;
    return {
      x: (x - this._canvas.offsetLeft + window.pageXOffset) * this._canvasDimensionsRatio.width,
      y: (y - this._canvas.offsetTop + window.pageYOffset) * this._canvasDimensionsRatio.height
    };
  };

  Controls.prototype._oneTouchRaycaster = function (point) {
    var normalize = this._normalizePosition(point);

    for (var i = 0; i < this._layers.length; i++) {
      var layer = this._layers[i];

      this._raycaster.setFromCamera(normalize, layer.camera);

      if (this._intersectObjects(layer)) return;
    }
  };

  Controls.prototype._multiTouchRaycaster = function (e) {
    if (e.changedTouches.length < 2 || this._selectedObject) return;
    var touch0 = e.changedTouches[0];
    var touch1 = e.changedTouches[1];

    var normalize0 = this._normalizePosition({
      x: touch0.clientX,
      y: touch0.clientY
    });

    var normalize1 = this._normalizePosition({
      x: touch1.clientX,
      y: touch1.clientY
    });

    var one = new Vector3(normalize0.x, normalize0.y, -1);
    var two = new Vector3(normalize1.x, normalize1.y, 1);

    for (var i = 0; i < this._layers.length; i++) {
      var layer = this._layers[i];
      one.unproject(layer.camera);
      two.unproject(layer.camera);
      one.z = 1e-128;
      two.z = -1e-128;
      var direction = new Vector3();
      direction.subVectors(two, one).normalize();

      this._raycaster.set(one, direction);

      this._intersectObjects(layer);
    }
  };

  Controls.prototype._setFrameLayerOpacity = function () {
    if (this._selectedLayer.name === 'stickers') Events.dispatch('frameOpacity');
  };

  Controls.prototype._clearFrameLayerOpacity = function () {
    if (this._selectedLayer.name === 'stickers') Events.dispatch('clearFrameOpacity');
  };

  Controls.prototype._onMouseDown = function () {
    var _this = this;

    this._canvas.addEventListener('mousedown', function (e) {
      var point = {
        x: e.clientX,
        y: e.clientY
      };
      if (!_this._selectedObject) _this._oneTouchRaycaster(point);

      _this._initMatrixTransform();

      if (_this._selectedObject) {
        _this._selectedObject.userData.transformation.startTouchPoint('mouse', point);

        _this._setFrameLayerOpacity();
      }

      _this._onMouseMove();
    });
  };

  Controls.prototype._onMouseMove = function () {
    var _this = this;

    this._mouseMoveFn = function (e) {
      if (_this._selectedObject) {
        _this._selectedObject.userData.transformation.moveTouchPoint('mouse', {
          x: e.clientX,
          y: e.clientY
        });
      }
    };

    this._mouseMoveFn = this._mouseMoveFn.bind(this);

    this._canvas.addEventListener('mousemove', this._mouseMoveFn);
  };

  Controls.prototype._onMouseUp = function () {
    function mouseUpFn() {
      if (this._selectedObject) {
        this._selectedObject.userData.transformation.endTouchPoint('mouse');

        this._selectedObject.dispatchEvent({
          type: 'moveEnd'
        });

        this._clearFrameLayerOpacity();
      }

      this._selectedObject = null;
      this._selectedLayer = null;

      this._canvas.removeEventListener('mousemove', this._mouseMoveFn);
    }

    this._canvas.addEventListener('mouseup', mouseUpFn.bind(this));

    this._canvas.addEventListener('mouseout', mouseUpFn.bind(this));
  };

  Controls.prototype._onTouchStart = function () {
    var _this = this;

    this._canvas.addEventListener('touchstart', function (e) {
      e.preventDefault();

      if (!_this._selectedObject) {
        if (e.changedTouches.length > 1) _this._multiTouchRaycaster(e);else _this._oneTouchRaycaster({
          x: e.changedTouches[0].clientX,
          y: e.changedTouches[0].clientY
        });
      }

      _this._initMatrixTransform();

      if (_this._selectedObject) {
        for (var i = 0; i < e.changedTouches.length; i++) {
          var touch = e.changedTouches[i];

          var relativePoint = _this._relativePosition({
            x: touch.clientX,
            y: touch.clientY
          });

          _this._selectedObject.userData.transformation.startTouchPoint(touch.identifier, relativePoint);
        }

        _this._setFrameLayerOpacity();
      }

      _this._onTouchMove();
    });
  };

  Controls.prototype._onTouchMove = function () {
    var _this = this;

    this._touchMoveFn = function (e) {
      if (_this._selectedObject) {
        for (var i = 0; i < e.changedTouches.length; i++) {
          var touch = e.changedTouches[i];

          var relativePoint = _this._relativePosition({
            x: touch.clientX,
            y: touch.clientY
          });

          _this._selectedObject.userData.transformation.moveTouchPoint(touch.identifier, relativePoint);
        }
      }
    };

    this._touchMoveFn = this._touchMoveFn.bind(this);

    this._canvas.addEventListener('touchmove', this._touchMoveFn);
  };

  Controls.prototype._onTouchEnd = function () {
    function touchEndFn(e) {
      if (this._selectedObject) {
        for (var i = 0; i < e.changedTouches.length; i++) {
          this._selectedObject.userData.transformation.endTouchPoint(e.changedTouches[i].identifier);
        }

        if (this._selectedObject.userData.transformation.getPointersLength() === 0) {
          this._selectedObject.dispatchEvent({
            type: 'moveEnd'
          });

          this._clearFrameLayerOpacity();

          this._selectedObject = null;
          this._selectedLayer = null;
        }
      }

      this._canvas.removeEventListener('touchmove', this._touchMoveFn);
    }

    this._canvas.addEventListener('touchend', touchEndFn.bind(this));

    this._canvas.addEventListener('touchcancel', touchEndFn.bind(this));
  };

  Controls.prototype._onUpdateTransform = function () {
    var _this = this;

    Events.on("updateMatrixTransformation", function () {
      if (!_this._selectedObject || !_this._selectedLayer) return;
      var _a = _this._selectedLayer,
          zoom = _a.zoom,
          camera = _a.camera,
          transformObject = _a.transformObject,
          enableRotate = _a.enableRotate,
          enableTranslation = _a.enableTranslation,
          enableZoom = _a.enableZoom;
      var transformation = _this._selectedObject.userData.transformation;
      var transform = transformation.getTransform();
      var prevTransform = _this._selectedObject.userData.prevTransform;
      _this._selectedObject.matrixAutoUpdate = false;

      if (enableTranslation) {
        var _b = transform.getTranslation(),
            tx = _b[0],
            ty = _b[1];

        if (transformObject === TransformObject.Object) {
          _this._selectedObject.position.x = tx - _this._width / 2;
          _this._selectedObject.position.y = -ty + _this._height / 2;
        } else if (transformObject === TransformObject.Camera) {
          _this._selectedObject.position.x = tx / camera.zoom - _this._width / 2 / camera.zoom;
          _this._selectedObject.position.y = -ty / camera.zoom + _this._height / 2 / camera.zoom;
        }
      }

      if (enableZoom) {
        var scale = transform.getScale();
        var prevScale = prevTransform.getScale();
        var differenceScale = scale - prevScale;

        if (transformObject === TransformObject.Object) {
          var currentObjectScale = _this._selectedObject.scale.x;
          var newScale = Math.min(Math.max(currentObjectScale + differenceScale, zoom.min), zoom.max);
          _this._selectedObject.scale.x = newScale;
          _this._selectedObject.scale.y = newScale;
        } else if (transformObject === TransformObject.Camera) {
          camera.zoom = Math.min(Math.max(camera.zoom + differenceScale, zoom.min), zoom.max);
        }
      }

      if (enableRotate) {
        var rotate = transform.getRotation();
        _this._selectedObject.rotation.z = -rotate;
      }

      _this._selectedObject.updateMatrix();

      if (enableZoom && transformObject === TransformObject.Camera) camera.updateProjectionMatrix();
      _this._selectedObject.userData.prevTransform = transformation.cloneTransform();

      _this._selectedObject.dispatchEvent({
        type: 'move'
      });
    });
  };

  Controls.prototype.addLayer = function (layer) {
    this._layers.splice(layer.priority, 0, layer);
  };

  Controls.prototype.deleteLayer = function (name) {
    this._layers = this._layers.filter(function (item) {
      return item.name !== name;
    });
  };

  return Controls;
}();

export { Controls };
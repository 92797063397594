export class DateValidations {
    static getDates(after, before, timeZone) {
        const formatterOptions = {
            year: '2-digit', 
            month: '2-digit', 
            day: '2-digit',
            hour: '2-digit', 
            minute: '2-digit', 
            second: '2-digit',
            timeZone: timeZone
        }
    
        const afterTZ = new Date(new Intl.DateTimeFormat("en-US", formatterOptions).format(new Date(after)));
        const beforeTZ = new Date(new Intl.DateTimeFormat("en-US", formatterOptions).format(new Date(before)));

        return {
            afterTZ: afterTZ,
            beforeTZ: beforeTZ
        }
    }

    static beforeOrEqual(after, before, timeZone) {
        const {afterTZ, beforeTZ} = DateValidations.getDates(after, before, timeZone);
        return afterTZ.getTime() <=  beforeTZ.getTime();
    }
}
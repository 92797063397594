import React, { Component } from 'react';
import './upload.scss';
import uploadIcon from './images/upload-image-icon.png';

class Upload extends Component {
    render() {
        return (
            <div id="camera-upload-btn" onClick={this.props.onClick}>
                <img src={uploadIcon} alt="Upload" />
            </div>
        )
    }
}

export {Upload}
var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import { TextureLoader, ClampToEdgeWrapping, LinearFilter } from 'three';
import Events from '../events';
import { Layer } from './';
import * as Debug from 'debug';
var debug = Debug('camera:FrameLayer');

var FrameLayer = function (_super) {
  __extends(FrameLayer, _super);

  function FrameLayer(dimensions, frameURL) {
    var _this = _super.call(this, dimensions) || this;

    _this._layerOpacity = 0.07;
    _this._frameURL = frameURL;

    _this.initCamera();

    _this.initTexture();

    _this.initMaterial(true);

    _this.initGeometry();

    _this.initMesh();

    _this.initScene();

    _this._onFrameOpacity();

    return _this;
  }

  FrameLayer.prototype._onFrameOpacity = function () {
    var _this = this;

    Events.on('frameOpacity', function () {
      _this.material.opacity = _this._layerOpacity;
      _this.material.needsUpdate = true;
    });
    Events.on('clearFrameOpacity', function () {
      _this.material.opacity = 1;
      _this.material.needsUpdate = true;
    });
  };

  FrameLayer.prototype.initTexture = function () {
    var _this = this;

    this.texture = new TextureLoader().load(this._frameURL, function () {
      debug('natural image size %o', {
        width: _this.texture.image.naturalWidth,
        height: _this.texture.image.naturalHeight
      });
    });
    this.texture.wrapS = ClampToEdgeWrapping;
    this.texture.wrapT = ClampToEdgeWrapping;
    this.texture.minFilter = LinearFilter;
    this.texture.magFilter = LinearFilter;
    debug('image texture %o', this.texture);
  };

  return FrameLayer;
}(Layer);

export { FrameLayer };
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import appReducer from '../reducers';

const middleware = applyMiddleware(
    thunk
);

const store = createStore(
    appReducer, 
    middleware
);

// expose store only in dev mode
if(process.env.NODE_ENV === 'development') window.store = store;

export default store;


import React, { Component } from 'react';
import './pic.scss';

class Pic extends Component {
    render() {
        return (
            <div id="camera-pic-btn" onClick={this.props.onClick}>
                <div></div>
            </div>
        )
    }
}

export {Pic}
import Events from '../events';

var Video = function () {
  function Video() {
    this._dom = this._createDOM();

    this._onLoadedMetaData();
  }

  Video.prototype._createDOM = function () {
    var elem = document.createElement("video");
    elem.setAttribute("autoplay", "true");
    elem.setAttribute("crossorigin", "anonymous");
    elem.setAttribute("loop", "true");
    elem.setAttribute("webkit-playsinline", "true");
    elem.setAttribute("playsinline", "true");
    elem.setAttribute("allowfullscreen", "false");
    elem.setAttribute("muted", "true");
    return elem;
  };

  Video.prototype._onLoadedMetaData = function () {
    this._dom.addEventListener("loadedmetadata", function (e) {
      Events.dispatch('videoLoadedMetaData');
    });
  };

  Video.prototype.setSrc = function (stream) {
    if ('srcObject' in this._dom) {
      this._dom.srcObject = stream;
    } else {
      this._dom.src = window.URL.createObjectURL(stream);
    }
  };

  Video.prototype.getElement = function () {
    return this._dom;
  };

  Video.prototype.play = function () {
    this._dom.play();
  };

  Video.prototype.pause = function () {
    this._dom.pause();
  };

  Video.prototype.getDimensions = function () {
    return {
      width: this._dom.videoWidth,
      height: this._dom.videoHeight
    };
  };

  return Video;
}();

export { Video };
import * as EXIF from 'exif-js';
import * as Debug from 'debug';
var debug = Debug('camera:Utils:Image');
export function getImageRotationDegrees(img) {
  return new Promise(function (resolve) {
    getImageExifTags(img).then(function (exif) {
      var deg = 0;

      if (exif && exif.Orientation) {
        deg = calculateImageRotationDegrees(exif.Orientation);
      }

      debug("rotation degrees " + deg);
      resolve(deg);
    });
  });
}
export function getImageExifTags(img) {
  return new Promise(function (resolve) {
    EXIF.getData(img, function () {
      var exifData = EXIF.getAllTags(this);
      debug('image exif tags data: %o', exifData);
      resolve(exifData);
    });
  });
}
export function calculateImageRotationDegrees(orientation) {
  var deg;
  debug("exif orientation " + orientation);

  switch (orientation) {
    case 3:
      deg = 180;
      break;

    case 5:
    case 6:
    case 7:
      deg = 90;
      break;

    case 8:
      deg = -90;
      break;

    default:
      deg = 0;
  }

  return deg;
}
import React, { Component } from 'react';
import './flip.scss';
import flipIcon from './images/flip-icon.png';

class Flip extends Component {
    render() {
        return (
            <div id="camera-flip-btn" onClick={this.props.onClick}>
                <img src={flipIcon} alt="flip" />
            </div>
        )
    }
}

export {Flip}
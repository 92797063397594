// create image file from base64
// @source https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob
export const base64ToBlob = (base64String, imageType = 'image/jpeg') => {
    const binStr = atob(base64String.split(',')[1]);
    const len = binStr.length;
    const arr = new Uint8Array(len);

    for(var i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
    }

    return new Blob([arr], {type: imageType});
}
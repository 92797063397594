import React, { Component } from 'react';
import {withTranslation} from 'react-i18next';
import {MainScreen} from '../../UiElements';
import './eventClosedPage.scss';
import balloon from './images/balloon.png';

class EventClosedPage extends Component {
    render() {
        const {t} = this.props;

        return (
            <MainScreen id="event-closed">
                <img className="balloon-image" src={balloon} alt="balloon"/>
                <div className="title">
                    {t('eventClosed')}
                </div>
            </MainScreen>
        );
    }
}

const EventClosedPageWithT = withTranslation()(EventClosedPage);
export {EventClosedPageWithT as EventClosedPage}
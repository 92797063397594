import Events from '../events';
import * as Debug from 'debug';
var debug = Debug('camera:Utils:UploadFromDevice');

var UploadFromDevice = function () {
  function UploadFromDevice(fileLastModifiedLimit) {
    this._inputAccept = 'image/jpeg,image/jpg,image/png';
    this._fileLastModifiedLimit = fileLastModifiedLimit;

    this._createInput();
  }

  ;

  UploadFromDevice.prototype._createInput = function () {
    this._input = document.createElement('input');
    this._input.type = 'file';
    this._input.accept = this._inputAccept;
    this._input.style.display = 'none';
    document.body.appendChild(this._input);
  };

  UploadFromDevice.prototype._fileLastModifiedValidation = function (file) {
    var _a = this._fileLastModifiedLimit,
        timeZone = _a.timeZone,
        date = _a.date;
    var formatterOptions = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: timeZone
    };
    var fileLastModifiedTz = new Date(new Intl.DateTimeFormat("en-US", formatterOptions).format(file.lastModifiedDate || file.lastModified));
    var afterTz = new Date(new Intl.DateTimeFormat("en-US", formatterOptions).format(date));
    return fileLastModifiedTz.getTime() >= afterTz.getTime();
  };

  UploadFromDevice.prototype._removeInput = function () {
    document.body.removeChild(this._input);
  };

  UploadFromDevice.prototype.onUpload = function () {
    var _this = this;

    return new Promise(function (resolve, reject) {
      _this._input.addEventListener('change', function (e) {
        var file = e.target.files[0];
        debug('Upload file from device %o', file);

        if (_this._fileLastModifiedLimit && !_this._fileLastModifiedValidation(file)) {
          _this._removeInput();

          var errorMsg = "file last modified date: " + new Date(file.lastModified) + " is earlier than the limit: " + _this._fileLastModifiedLimit.date;
          debug('Error: ' + errorMsg);
          Events.dispatch('fileLastModifiedExceededLimitError');
          return reject(new Error(errorMsg));
        }

        _this._removeInput();

        resolve(file);
      });
    });
  };

  UploadFromDevice.prototype.openCameraImmediately = function () {
    this._input.setAttribute('capture', 'camera');
  };

  UploadFromDevice.prototype.open = function () {
    this._input.click();
  };

  return UploadFromDevice;
}();

export { UploadFromDevice };
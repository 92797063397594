var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import { VideoTexture, LinearFilter, ClampToEdgeWrapping, RGBFormat, PlaneGeometry } from 'three';
import { getOrthographicCameraZoom, TransformObject, SelfieCamera } from '../utils';
import { Layer } from './';
import * as Debug from 'debug';
var debug = Debug('camera:FrameLayer');

var VideoLayer = function (_super) {
  __extends(VideoLayer, _super);

  function VideoLayer(dimensions, video, selfieCamera, controls) {
    var _this = _super.call(this, dimensions) || this;

    _this._video = video;
    _this._controls = controls;
    _this._selfieCamera = selfieCamera;
    debug('selfie camera: %s', _this._selfieCamera);

    _this.initCamera();

    _this.initTexture();

    _this.initMaterial();

    _this.initGeometry();

    _this.initMesh();

    _this.initScene();

    return _this;
  }

  VideoLayer.prototype.initTexture = function () {
    this.texture = new VideoTexture(this._video.getElement());
    this.texture.minFilter = LinearFilter;
    this.texture.magFilter = LinearFilter;
    this.texture.wrapS = ClampToEdgeWrapping;
    this.texture.wrapT = ClampToEdgeWrapping;
    this.texture.format = RGBFormat;
  };

  VideoLayer.prototype.initGeometry = function () {
    var dimensions = this._video.getDimensions();

    var vWidth = dimensions.width;
    var vHeight = dimensions.height;
    var width = vWidth === 0 ? 1 : vWidth;
    var height = vHeight === 0 ? 1 : vHeight;
    this.geometry = new PlaneGeometry(width, height);
  };

  VideoLayer.prototype.update = function (selfieCamera) {
    var _a = this._video.getDimensions(),
        width = _a.width,
        height = _a.height;

    debug('Update video dimensions %o', {
      width: width,
      height: height
    });
    this._selfieCamera = selfieCamera;
    debug("Update selfieCamera arg: " + this._selfieCamera);
    this.mesh.scale.x = width * (this._selfieCamera === SelfieCamera.User ? -1 : 1);
    this.mesh.scale.y = height;
    var zoom = getOrthographicCameraZoom({
      width: width,
      height: height
    }, {
      width: this.width,
      height: this.height
    });
    this.camera.zoom = zoom;

    this._controls.deleteLayer(VideoLayer._layerName);

    this._controls.addLayer({
      camera: this.camera,
      scene: this.scene,
      priority: VideoLayer._layerPriority,
      name: VideoLayer._layerName,
      transformObject: TransformObject.Camera,
      zoom: {
        min: zoom,
        max: Infinity,
        start: zoom
      },
      enableZoom: true,
      enableRotate: false,
      enableTranslation: false
    });

    this.camera.updateProjectionMatrix();
    debug("Camera zoom " + this.camera.zoom);
  };

  VideoLayer._layerName = 'video';
  VideoLayer._layerPriority = 2;
  return VideoLayer;
}(Layer);

export { VideoLayer };
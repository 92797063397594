import React, { Component } from 'react';
import {StickerActions} from '../../../../actions';
import {StickerItem} from './';
import './stickerList.scss';

class StickerList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stickersUrl: [],
            stickers: [],
            defaultStickers: {
                url: '/images/stickers/',
                ex: 'svg',
                start: 1,
                end: 51,
                prefix: ''
            }
        }
    }

    componentDidMount() {
        this.setState({stickersUrl: StickerActions.getStickersUrls(this.state.defaultStickers)})
    }

    renderStickerList() {
        return this.state.stickersUrl.map((url, i) => (
            <StickerItem onClick={this.props.onStickerClick} src={url} key={i}/>
        ));
    }

    render() {
        if(!this.props.show) return null;

        return (
            <div id="camera-sticker-list">
                <div className="stickers-sep"></div>
                <div className="stickers-wrapper">
                    {this.renderStickerList()}
                </div>
            </div>
        )
    }
}

export {StickerList}
import React, { Component } from 'react';
import {withTranslation} from 'react-i18next';
import './horizontalScreen.scss';

class HorizontalScreen extends Component {
    render() {
        return (
            <div id="horizontal-screen">
                <div className="horizontal-screen-warning">
                    {this.props.t('horizontalScreenWarning')}
                </div>
            </div>
        )
    }
}

const HorizontalScreenWithT = withTranslation()(HorizontalScreen);
export {HorizontalScreenWithT as HorizontalScreen}
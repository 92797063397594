import React, { Component } from 'react';
import downloadIcon from './images/download-icon.png';
import './download.scss';

class Download extends Component {
    render() {
        return (
            <div id="camera-download-btn" onClick={this.props.onClick}>
                <img src={downloadIcon} alt="download" />
            </div>
        )
    }
}

export {Download}
var Events = function () {
  function Events() {
    this._list = {};
  }

  Events.getInstance = function () {
    if (!Events._instance) {
      Events._instance = new Events();
    }

    return Events._instance;
  };

  Events.prototype.on = function (events, callback) {
    var list = events.split(' ');
    list.forEach(function (event) {
      window.addEventListener(event, callback);
    });
  };

  Events.prototype.off = function (events, callback) {
    var _this = this;

    var list = events.split(' ');
    list.forEach(function (name) {
      if (_this._list[name]) delete _this._list[name];
      window.removeEventListener(name, callback);
    });
  };

  Events.prototype.dispatch = function (name, data) {
    if (!this._list.hasOwnProperty(name)) this._list[name] = new CustomEvent(name, {
      detail: data
    });
    window.dispatchEvent(this._list[name]);
  };

  return Events;
}();

var singleton = Events.getInstance();
export default singleton;
import {cameraConstants} from '../constants';

const cameraReducer = (state = {}, action) => {
    switch(action.type) {
        case cameraConstants.SET_COPIES:
            return {
                ...state,
                copies: action.payload
            }

        case cameraConstants.INC_COPIES:
            return {
                ...state,
                copies: state.copies + 1
            }

        case cameraConstants.DEC_COPIES:
            return {
                ...state,
                copies: state.copies - 1
            }

        case cameraConstants.PLAY:
        case cameraConstants.FLIP:
            return {
                ...state,
                copies: 1,
                level: 1,
                uploadImageBlocked: false
            }

        case cameraConstants.PIC:
        case cameraConstants.UPLOAD_IMAGE_SUCCESS:
            return {
                ...state,
                level: 2,
                uploadImageBlocked: false
            }

        case cameraConstants.UPLOAD_IMAGE_BLOCKED:
            return {
                ...state,
                uploadImageBlocked: true
            }

        case cameraConstants.UPLOAD_IMAGE_REMOVE_BLOCKED:
            return {
                ...state,
                uploadImageBlocked: false
            }

        default: 
            return state;
    }
}

export default cameraReducer;
import 'webrtc-adapter/out/adapter';
import Events from './events';
import * as Debug from 'debug';
var debug = Debug('camera:DeviceCamera');

var DeviceCamera = function () {
  function DeviceCamera() {}

  DeviceCamera.prototype.open = function (video, selfieCamera, width, height) {
    var _this = this;

    if (window.navigator.mediaDevices && window.navigator.mediaDevices.getUserMedia) {
      var constraints = {
        video: {
          width: width,
          height: height,
          facingMode: selfieCamera
        }
      };
      this.close();
      window.navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
        _this._stream = stream;
        _this._tracks = _this._stream.getTracks();
        video.setSrc(_this._stream);
      }).catch(function (e) {
        debug('Device camera blocked: %o', e);
        Events.dispatch('deviceCameraBlocked', e);
      });
    } else {
      debug('webRTC unsupported');
      Events.dispatch('webRTCUnsupported');
    }
  };

  DeviceCamera.prototype.close = function () {
    if (!this._tracks) return;

    this._tracks.forEach(function (track) {
      return track.stop();
    });

    this._stream = null;
    Events.dispatch('deviceCameraClosed');
  };

  return DeviceCamera;
}();

export { DeviceCamera };
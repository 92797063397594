import {EventService} from '../services';
import {eventConstants, uploadConstants} from '../constants';
import {redirect, DateValidations} from '../utils';
import store from '../store';

export class EventActions {
    static getEventShortenUrl() {
        return window.location.pathname.split('/')[1];
    }

    static getEventData() {
        return (dispatch) => {
            const url = EventActions.getEventShortenUrl();
            if(['error-404', 'event-finished'].indexOf(url) > -1) return;

            EventService.getEventData(url)
                .then((res) => {
                    if(res.status !== 200) throw new Error(res.status);
                    return res.json();
                })
                .then((data) => {
                    dispatch({type: eventConstants.GET_EVENT_DATA, payload: data});
                    
                    if(data.closed) {
                        return redirect(`/${url}/event-closed`);
                    }

                    // if user already exists in event-closed page
                    if(data.closed === false && window.location.pathname.indexOf('event-closed') > -1) {
                        return redirect(`/${url}`);
                    } 

                    if(data.date && data.date.end) {
                        if(DateValidations.beforeOrEqual(data.date.end, new Date(), data.date.timzZone)) {
                            return redirect(`/${url}/event-finished`);
                        }
                    }

                    if(data.date && data.date.start) {
                        if(!DateValidations.beforeOrEqual(data.date.start, new Date(), data.date.timzZone)) {
                            return redirect(`/${url}/event-waiting`);
                        } else if(window.location.pathname.indexOf('event-waiting') > -1) {
                            return redirect(`/${url}`);
                        }
                    }

                }).catch(() => {
                    const currentState = store.getState();

                    // name is required for event
                    // prevent redirect to 404 if event already exists in the store
                    if(currentState.eventReducer.name) return;

                    dispatch({type: eventConstants.ERROR_EVENT_NOT_FOUND});
                    if(url) return redirect(`/${url}/error-404`);
                });
        }
    }

    static postImage({image, copies}) {
        return (dispatch) => {
            dispatch({type: uploadConstants.UPLOAD_REQUEST});
            
            let progressInterval;
            let p = 0;
            let prevP = p;

            const onreadystatechange = function () {
                clearInterval(progressInterval);
    
                if(this.status === 201 && this.readyState === 4) {
                    dispatch({type: uploadConstants.SET_UPLOAD_PROCESS, payload: 99});
                    dispatch({type: uploadConstants.UPLOAD_SUCCESS});

                    

                    if(window.gtag) {
                        window.gtag('event', 'Send', {
                            'event_category': 'Webcam',
                            'event_label': 'success',
                            'value': copies
                        });
                    }

                } else if(this.status !== 201) {
                    dispatch({type: uploadConstants.UPLOAD_FAILURE});
                    dispatch(EventActions.getEventData()); // update event data (if event close or user limit was changed)

                    const {meta} = JSON.parse(this.responseText);

                    if(window.gtag) {   
                        window.gtag('event', 'Error', {
                            'event_category': 'Webcam',
                            'event_label': `Status: ${this.status}, Message: ${meta.message}`
                        });
                    }
                }
            }

            const onprogress = (e) => {
                prevP = p;
                p = Math.ceil((e.loaded / e.total) * 100);
                clearInterval(progressInterval);

                p = Math.min(p, 99); // max is 99%

                progressInterval = setInterval(() => {
                    prevP = Math.min(100, prevP + 1);
                    dispatch({type: uploadConstants.SET_UPLOAD_PROCESS, payload: prevP});

                    if(prevP >= p) clearInterval(progressInterval);
                }, 30);
            }

            EventService.postImage({
                image: image,
                copies: copies,
                onreadystatechange: onreadystatechange,
                onprogress: onprogress,
                eventShortenUrl: EventActions.getEventShortenUrl()
            });
        }
    }

    static pageTitle(title) {
        document.title = title ? `SELPHO | ${title}` : 'SELPHO';
    }
}
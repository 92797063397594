import { Math as Math3, WebGLRenderer, Clock } from 'three';
import * as Debug from 'debug';
import { PostProcessingState } from './utils';
import { Controls } from './controls';
import { PostProcessing } from './postProcessing';
import { VideoLayer, FrameLayer, StickersLayer, ImageLayer } from './layers';
import Events from './events';
import { getPixelRatioHistory, setPixelRatioHistory } from './utils/pixelRatio';
var debug = Debug('camera:Render');

var Render = function () {
  function Render(dimensions, canvas, video, selfieCamera, frameURL, trash) {
    this._postProcessingState = null;
    this._id = Math3.generateUUID();
    this._dimensions = dimensions;
    this._canvas = canvas;
    this._video = video;
    this._frameURL = frameURL;
    this._trash = trash;
    this._selfieCamera = selfieCamera;

    this._initRenderer();

    this._initPostProcessing();

    this._initContols();

    this._initLayers();
  }

  Render.prototype._initRenderer = function () {
    this._renderer = new WebGLRenderer({
      antialias: false,
      alpha: false,
      canvas: this._canvas,
      preserveDrawingBuffer: true
    });
    this._gl = this._renderer.getContext();
    debug('MAX_RENDERBUFFER_SIZE:', this._gl.getParameter(this._gl.MAX_RENDERBUFFER_SIZE));
    debug('MAX_VIEWPORT_DIMS:', this._gl.getParameter(this._gl.MAX_VIEWPORT_DIMS));
    debug('MAX_TEXTURE_SIZE:', this._gl.getParameter(this._gl.MAX_TEXTURE_SIZE));
    this._pixelRatio = getPixelRatioHistory();

    this._renderer.setPixelRatio(this._pixelRatio);

    this._renderer.setSize(this._dimensions.width, this._dimensions.height);

    this._renderer.autoClear = false;
    debug("devicePixelRatio " + (window.devicePixelRatio || 1) + ", pixelRatioHistory " + this._pixelRatio);
  };

  Render.prototype._initPostProcessing = function () {
    this._postProcessing = new PostProcessing(this._renderer);
  };

  Render.prototype._initContols = function () {
    this._controls = new Controls(this._canvas, this._dimensions);
  };

  Render.prototype._initLayers = function () {
    this._videoLayer = new VideoLayer(this._dimensions, this._video, this._selfieCamera, this._controls);
    this._frameLayer = new FrameLayer(this._dimensions, this._frameURL);
    this._stickersLayer = new StickersLayer(this._dimensions, this._controls, this._trash);
  };

  Render.prototype.animate = function () {
    this.stop();
    var clock = new Clock();
    var delta;
    var webglErrors = 0;

    var frameLayerCamera = this._frameLayer.getCamera();

    var frameLayerScene = this._frameLayer.getScene();

    var stickersLayerCamera = this._stickersLayer.getCamera();

    var stickersLayerScene = this._stickersLayer.getScene();

    var self = this;

    (function loop() {
      Render._requestAnimationFrameIDsList[self._id] = window.requestAnimationFrame(loop);
      delta = clock.getDelta();
      webglErrors = self._gl.getError();

      if (webglErrors > 0) {
        if (self._pixelRatio > 1) {
          debug('Webgl error, update canvas pixel ratio');
          setPixelRatioHistory();
          window.location.reload();
        } else {
          Events.dispatch('webglRenderError');
        }
      }

      self._renderer.clear();

      self._postProcessing.render(delta);

      self._renderer.clearDepth();

      self._renderer.render(stickersLayerScene, stickersLayerCamera);

      self._renderer.clearDepth();

      frameLayerCamera.lookAt(frameLayerScene.position);

      self._renderer.render(frameLayerScene, frameLayerCamera);
    })();
  };

  Render.prototype.isAnimate = function () {
    return Render._requestAnimationFrameIDsList.hasOwnProperty(this._id);
  };

  Render.prototype.stop = function () {
    if (!Render._requestAnimationFrameIDsList[this._id]) return;
    window.cancelAnimationFrame(Render._requestAnimationFrameIDsList[this._id]);
    debug("cancelAnimationFrame: " + Render._requestAnimationFrameIDsList[this._id]);
    delete Render._requestAnimationFrameIDsList[this._id];
  };

  Render.prototype.drawImage = function (imageURL, rotationDegrees) {
    if (this._imageLayer) {
      this._imageLayer.dispose();

      debug('dispose imageLayer');
    }

    this._imageLayer = new ImageLayer(this._dimensions, imageURL, rotationDegrees, this._controls);

    this._postProcessing.reset();

    this._postProcessing.hideShader(['sobelInvert', 'sobel']);

    this._postProcessing.setResolution(this._imageLayer.getImageNaturalDimensions());

    this._postProcessing.renderPass(this._imageLayer.getScene(), this._imageLayer.getCamera());

    this._postProcessingState = PostProcessingState.Image;
    Events.dispatch('postProcessingStateChange');
  };

  Render.prototype.updateVideo = function (selfieCamera) {
    this._videoLayer.update(selfieCamera);

    this._postProcessing.setResolution(this._video.getDimensions());

    if (this._postProcessingState !== PostProcessingState.Video) {
      this._postProcessing.reset();

      this._postProcessing.showShader(['sobelInvert', 'sobel']);

      this._postProcessing.renderPass(this._videoLayer.getScene(), this._videoLayer.getCamera());

      this._postProcessingState = PostProcessingState.Video;
      Events.dispatch('postProcessingStateChange');
    }
  };

  Render.prototype.addSticker = function (stickerData) {
    this._stickersLayer.addSticker(stickerData);
  };

  Render.prototype.clearAllStickers = function () {
    this._stickersLayer.clearAllStickers();
  };

  Render.prototype.getPostProcessing = function () {
    return this._postProcessing;
  };

  Render._requestAnimationFrameIDsList = {};
  return Render;
}();

export { Render };
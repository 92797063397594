import {Camera as Cam} from 'camera';
import {cameraConstants} from '../constants';
import {base64ToBlob, bugsnagClient} from '../utils';

const {REACT_APP_FALLBACK_BASE_URL} = process.env;

class CameraActions {
    constructor() {
        this.cam = null;
        this.eventShortUrl = null;
    }

    isInit() {
        return this.cam == null ? false : true;
    }

    init(event, canvas, eventShortUrl) {
        return (dispatch) => {
            if(this.cam || !event || event.closed !== false) return;
            const {size, url} = event.frames.length > 0 ? event.frames[0] : {url: "", size: {x: window.innerHeight, y: window.innerHeight}};
            this.eventShortUrl = eventShortUrl;

            const frameSize = {
                width: size.x,
                height: size.y
            }
    
            const trashSticker = {
                sticker: {
                    url: '/images/trash-icon.png',
                    width: 120,
                    height: 150
                },
                position: {
                    x: 0,
                    y: -size.y / 3 - 75
                }
            }
    
            const drawImageAfterDate = {
                date: new Date(event.date.start),
                timeZone: event.date.timeZone
            }

            window.addEventListener('deviceCameraBlocked', CameraActions.onDeviceCameraBlocked);
            window.addEventListener('webRTCUnsupported', CameraActions.onWebRTCUnsupported);
            window.addEventListener('webglUnavailable', CameraActions.onWebglUnavailable);
            window.addEventListener('webglRenderError', this.onWebglRenderError.bind(this));
    
            this.cam = new Cam(
                frameSize,
                canvas,
                'user',
                url,
                trashSticker,
                drawImageAfterDate
            );

            dispatch(this.play());
    
            // for dev
            if(process.env.NODE_ENV === 'development') window.cam = this.cam;
        } 
    }

    onWebglRenderError() {
        if(window.gtag) {
            window.gtag('event', 'Error', {
                'event_category': 'Webcam',
                'event_label': 'WebGL render error - redirect'
            });
        }

        if(REACT_APP_FALLBACK_BASE_URL && this.eventShortUrl && this.eventShortUrl !== 'demo-event') {
            window.location.href = `${REACT_APP_FALLBACK_BASE_URL}/${this.eventShortUrl}`;
        }
    }

    static onWebglUnavailable() {
        if(window.gtag) {
            window.gtag('event', 'Error', {
                'event_category': 'Webcam',
                'event_label': 'WebGL Unavailable'
            });
        }
    }

    static onDeviceCameraBlocked(e) {
        if(e.detail && e.detail.message === 'Permission denied') {
            if(window.gtag) {
                window.gtag('event', 'Error', {
                    'event_category': 'Webcam',
                    'event_label': 'Permission denied'
                });
            }
        } else {
            bugsnagClient.notify(new Error(e.detail.toString()));
            if(window.gtag) {
                window.gtag('event', 'Error', {
                    'event_category': 'Webcam',
                    'event_label': e.detail.message
                });
            }
        }
    }

    static onWebRTCUnsupported() {
        if(window.gtag) {
            window.gtag('event', 'Error', {
                'event_category': 'Webcam',
                'event_label': 'webRTC not supported'
            });
        }
    }

    stop() {
        if(!this.isInit()) return;
        this.cam.pic(); // stop user camera
        this.cam.stop();
        this.cam = null;

        window.removeEventListener('deviceCameraBlocked', CameraActions.onDeviceCameraBlocked);
        window.removeEventListener('webRTCUnsupported', CameraActions.onWebRTCUnsupported);
        window.removeEventListener('webglUnavailable', CameraActions.onWebglUnavailable);
    }

    play() {
        return (dispatch) => {
            if(this.isInit()) {
                this.cam.play();
                dispatch({type: cameraConstants.PLAY});
                
                if(window.gtag) {
                    window.gtag('event', 'Play', {
                        'event_category': 'Webcam'
                    });
                }
            }
        }
    }

    flip() {
        return (dispatch) => {
            if(this.isInit()) {
                this.cam.flip();
                dispatch({type: cameraConstants.FLIP});

                if(window.gtag) {
                    window.gtag('event', 'Flip', {
                        'event_category': 'Webcam'
                    });
                }
            }
        }
    }

    pic() {
        return (dispatch) => {
            if(this.isInit()) {
                this.cam.pic();
                dispatch({type: cameraConstants.PIC});

                if(window.gtag) {
                    window.gtag('event', 'Pic', {
                        'event_category': 'Webcam'
                    });
                }
            }
        }
    }

    upload() {
        return (dispatch) => {
            if(!this.isInit()) return;
            return this.cam.uploadFromDevice()
                .then(() => {
                    dispatch({type: cameraConstants.UPLOAD_IMAGE_SUCCESS});

                    if(window.gtag) {
                        window.gtag('event', 'Success upload from device', {
                            'event_category': 'Upload'
                        });
                    }
                })
                .catch(() => {
                    dispatch({type: cameraConstants.UPLOAD_IMAGE_BLOCKED});

                    window.gtag('event', 'Error', {
                        'event_category': 'Upload',
                        'event_label': 'Image unconnected to event'
                    });
                });
        }
    }

    restart() {
        return (dispatch) => {
            if(!this.isInit()) return;

            this.cam.clearAllStickers(); // reset stickers
            this.cam.setEffect(''); // reset filter

            dispatch(this.play());
        } 
    }

    onFilterListChage(fn) {
        window.addEventListener('postProcessingStateChange', fn);
    }

    removeOnFilterListChage(fn) {
        window.removeEventListener('postProcessingStateChange', fn);
    }

    getFilterList() {
        if(this.isInit()) return this.cam.getEffectsList();
        return [];
    }

    setFilter(filter) {
        if(!this.isInit()) return;
        
        this.cam.setEffect(filter);

        if(window.gtag) {
            window.gtag('event', 'Set filter', {
                'event_category': 'Filters',
                'event_label': filter
            });
        }
    }

    addSticker({name, width, height, url}) {
        if(!this.isInit()) return;
        
        this.cam.addSticker({name, width, height, url});

        if(window.gtag) {
            window.gtag('event', 'Add new sticker', {
                'event_category': 'Stickers',
                'event_label': name
            });
        }
    }   

    getBase64(mime = 'image/jpeg') {
        // this.cam default mime: 'image/jpeg'
        if(this.isInit()) return this.cam.exportAsBase64(mime);
    }

    getBlob(mime = 'image/jpeg') {
        if(!this.isInit()) return; 
        const base64 = this.getBase64(mime);
        return base64ToBlob(base64, mime);
    }

    download(name, mime = 'image/jpeg') {
        if(!this.isInit()) return;
        const a = document.createElement('a');
        a.href = this.getBase64(mime);
        a.setAttribute('download', name);
        a.style.display = 'none';
        
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        if(window.gtag) {
            window.gtag('event', 'Download', {
                'event_category': 'Webcam'
            });
        }
    }

    resetCopies() {
        return (dispatch) => {
            dispatch({type: cameraConstants.SET_COPIES, payload: 1});
        }
    }
}

const singleton = new CameraActions();
export {singleton as CameraActions}
import React, { Component } from 'react';
import {withTranslation} from 'react-i18next';
import {MainScreen} from '../../UiElements';
import {EventActions} from '../../../actions';
import './eventFinishedPage.scss';
import finishedIcon from './images/event-finished-icon.png';

class EventFinishedPage extends Component {
    componentWillMount() {
        EventActions.pageTitle(this.props.t('eventFinished'));
    }

    render() {
        const {t} = this.props;

        return (
            <MainScreen id="event-finished">
                <div className="finished-wrapper">
                    <img className="finished-image" src={finishedIcon} alt="Finished"/>
                    <div className="finished-title">
                        {t('eventFinished')}
                    </div>
                </div>
            </MainScreen>
        );
    }
}

const EventFinishedPageWithT = withTranslation()(EventFinishedPage);
export {EventFinishedPageWithT as EventFinishedPage}
import { Vector2 } from 'three';
import { EffectComposer } from 'three/examples/js/postprocessing/EffectComposer';
import { ShaderPass } from 'three/examples/js/postprocessing/ShaderPass';
import { RenderPass } from 'three/examples/js/postprocessing/RenderPass';
import { CopyShader } from 'three/examples/js/shaders/CopyShader';
import { SepiaShader } from 'three/examples/js/shaders/SepiaShader';
import { DotScreenShader } from 'three/examples/js/shaders/DotScreenShader';
import { SobelOperatorShader } from 'three/examples/js/shaders/SobelOperatorShader';
import { BrightnessContrastShader } from 'three/examples/js/shaders/BrightnessContrastShader';
import { GammaCorrectionShader } from 'three/examples/js/shaders/GammaCorrectionShader';
import { VignetteShader } from 'three/examples/js/shaders/VignetteShader';
import { TechnicolorShader } from 'three/examples/js/shaders/TechnicolorShader';
import { GreyscaleLuminanceShader, SobelShaderInvert } from './shaders';
import * as Debug from 'debug';
var debug = Debug('camera:PostProcessing');

var PostProcessing = function () {
  function PostProcessing(renderer) {
    this._renderPassIndex = 0;
    this._shaders = {};
    this._hideShaderFromTheList = {};
    this._renderer = renderer;

    this._initComposer();

    this._initShaders();
  }

  PostProcessing.prototype._initComposer = function () {
    this._composer = new EffectComposer(this._renderer);
  };

  PostProcessing.prototype._initShaders = function () {
    this._shaders.sepia = new ShaderPass(SepiaShader);
    this._shaders.sepia.enabled = false;
    this._hideShaderFromTheList.sepia = false;

    this._composer.addPass(this._shaders.sepia);

    this._shaders.dotScreen = new ShaderPass(DotScreenShader);
    this._shaders.dotScreen.uniforms.scale.value = 2;
    this._shaders.dotScreen.enabled = false;
    this._hideShaderFromTheList.dotScreen = false;

    this._composer.addPass(this._shaders.dotScreen);

    this._shaders.greyscale = new ShaderPass(GreyscaleLuminanceShader);
    this._shaders.greyscale.enabled = false;
    this._hideShaderFromTheList.greyscale = false;

    this._composer.addPass(this._shaders.greyscale);

    this._shaders.sobel = new ShaderPass(SobelOperatorShader);
    this._shaders.sobel.enabled = false;
    this._hideShaderFromTheList.sobel = false;

    this._composer.addPass(this._shaders.sobel);

    this._shaders.sobelInvert = new ShaderPass(SobelShaderInvert);
    this._shaders.sobelInvert.enabled = false;
    this._hideShaderFromTheList.sobelInvert = false;

    this._composer.addPass(this._shaders.sobelInvert);

    this._shaders.gammaCorrection = new ShaderPass(GammaCorrectionShader);
    this._shaders.gammaCorrection.enabled = false;
    this._hideShaderFromTheList.gammaCorrection = false;

    this._composer.addPass(this._shaders.gammaCorrection);

    this._shaders.vignette = new ShaderPass(VignetteShader);
    this._shaders.vignette.uniforms.offset.value = 2;
    this._shaders.vignette.enabled = false;
    this._hideShaderFromTheList.vignette = false;

    this._composer.addPass(this._shaders.vignette);

    this._shaders.technicolorShader = new ShaderPass(TechnicolorShader);
    this._shaders.technicolorShader.enabled = false;
    this._hideShaderFromTheList.technicolorShader = false;

    this._composer.addPass(this._shaders.technicolorShader);

    this._brightnessContrast = new ShaderPass(BrightnessContrastShader);
    this._brightnessContrast.enabled = true;

    this._composer.addPass(this._brightnessContrast);

    var copyShader = new ShaderPass(CopyShader);
    copyShader.renderToScreen = true;

    this._composer.addPass(copyShader);
  };

  PostProcessing.prototype.renderPass = function (scene, camera) {
    this._composer.insertPass(new RenderPass(scene, camera), this._renderPassIndex++);

    if (this._renderPassIndex > 1) {
      this._composer.passes.shift();

      this._renderPassIndex--;
    }

    debug('Composer passes: %o', this._composer.passes);
  };

  PostProcessing.prototype.getShadersNamesList = function () {
    var _this = this;

    return Object.keys(this._shaders).filter(function (item) {
      return _this._hideShaderFromTheList[item] === false;
    });
  };

  PostProcessing.prototype.enableShader = function (shaderName) {
    for (var p in this._shaders) {
      if (p === shaderName) {
        this._shaders[p].enabled = true;
      } else {
        this._shaders[p].enabled = false;
      }
    }
  };

  PostProcessing.prototype.hideShader = function (shaderName) {
    var _this = this;

    debug('Hide shaders: %o', shaderName);

    if (Array.isArray(shaderName)) {
      shaderName.forEach(function (item) {
        _this._hideShaderFromTheList[item] = true;
      });
    } else {
      this._hideShaderFromTheList[shaderName] = true;
    }
  };

  PostProcessing.prototype.showShader = function (shaderName) {
    var _this = this;

    debug('Show shaders: %o', shaderName);

    if (Array.isArray(shaderName)) {
      shaderName.forEach(function (item) {
        _this._hideShaderFromTheList[item] = false;
      });
    } else {
      this._hideShaderFromTheList[shaderName] = false;
    }
  };

  PostProcessing.prototype.setBrightness = function (value) {
    if (value < -1 || value > 1) return;
    this._brightnessContrast.uniforms.brightness.value = value;
  };

  PostProcessing.prototype.setContrast = function (value) {
    if (value < -1 || value > 1) return;
    this._brightnessContrast.uniforms.contrast.value = value;
  };

  PostProcessing.prototype.reset = function () {
    this.enableShader('');
    this.setBrightness(0);
    this.setContrast(0);
    debug('Reset');
  };

  PostProcessing.prototype.setResolution = function (dimensions) {
    var resolution = new Vector2(dimensions.width, dimensions.height);
    this._shaders.sobel.uniforms.resolution.value = resolution;
    this._shaders.sobelInvert.uniforms.resolution.value = resolution;
  };

  PostProcessing.prototype.render = function (delta) {
    this._composer.render(delta);
  };

  return PostProcessing;
}();

export { PostProcessing };
import * as Nudged from 'nudged';
import Events from '../events';

var MatrixTransformation = function () {
  function MatrixTransformation(mode, translateX, translateY, scale) {
    if (scale === void 0) {
      scale = 1;
    }

    this._pointers = {};
    this._mode = mode;
    this._matrixState = Nudged.Transform.IDENTITY.scaleBy(scale).translateBy(translateX, translateY);
    this._transform = this._matrixState;
  }

  MatrixTransformation.prototype._beforeChangePointersLength = function () {
    var domain = [];
    var range = [];

    for (var id in this._pointers) {
      if (!this._pointers.hasOwnProperty(id)) continue;
      var point = this._pointers[id];
      domain.push([point.dx, point.dy]);
      range.push([point.rx, point.ry]);
      point.dx = point.rx;
      point.dy = point.ry;
    }

    var transformation = Nudged.estimate(this._mode, domain, range);
    this._matrixState = transformation.multiplyBy(this._matrixState);
    this._transform = this._matrixState;
  };

  MatrixTransformation.prototype._updateTransform = function () {
    var domain = [];
    var range = [];

    for (var id in this._pointers) {
      if (!this._pointers.hasOwnProperty(id)) continue;
      var point = this._pointers[id];
      domain.push([point.dx, point.dy]);
      range.push([point.rx, point.ry]);
    }

    var transformation = Nudged.estimate(this._mode, domain, range);
    this._transform = transformation.multiplyBy(this._matrixState);
    Events.dispatch('updateMatrixTransformation');
  };

  MatrixTransformation.prototype.startTouchPoint = function (id, point) {
    var x = point.x,
        y = point.y;

    this._beforeChangePointersLength();

    this._pointers[id] = {
      dx: x,
      dy: y,
      rx: x,
      ry: y
    };

    this._updateTransform();
  };

  MatrixTransformation.prototype.moveTouchPoint = function (id, point) {
    if (!this._pointers.hasOwnProperty(id)) return;
    var x = point.x,
        y = point.y;
    this._pointers[id].rx = x;
    this._pointers[id].ry = y;

    this._updateTransform();
  };

  MatrixTransformation.prototype.endTouchPoint = function (id) {
    if (this._pointers.hasOwnProperty(id)) {
      this._beforeChangePointersLength();

      delete this._pointers[id];
    }
  };

  MatrixTransformation.prototype.getTransform = function () {
    return this._transform;
  };

  MatrixTransformation.prototype.getPointersLength = function () {
    return Object.keys(this._pointers).length;
  };

  MatrixTransformation.prototype.cloneTransform = function () {
    return Nudged.createFromArray(this._transform.toArray());
  };

  return MatrixTransformation;
}();

export { MatrixTransformation };
import React, { Component } from 'react';
import {connect} from 'react-redux';
import {cameraConstants} from '../../../../constants'
import {withTranslation} from 'react-i18next';
import './copies.scss';

class Copies extends Component {
    onInc() {
        const {event, camera, dispatch} = this.props;
        if(event.closed !== false) return
        if(camera.copies >= event.maxImages.copy) return;

        dispatch({type: cameraConstants.INC_COPIES});
    }

    onDec() {
        const {event, camera, dispatch} = this.props;
        if(event.closed !== false) return
        if(camera.copies <= 1) return;

        dispatch({type: cameraConstants.DEC_COPIES});
    }

    render() {
        const {copies} = this.props.camera;
        const {maxImages} = this.props.event;

        return (
            <div id="camera-copies-wrapper">
                <div className="copies-btns-wrapper">
                    <div className={`copies-btn copies-inc ${maxImages && maxImages.copy === copies ? 'disabled' : ''}`} onClick={this.onInc.bind(this)}>
                        +
                    </div>
                    <div className="copies-number">
                        {copies}
                    </div>
                    <div className={`copies-btn copies-dec ${copies === 1 ? 'disabled' : ''}`} onClick={this.onDec.bind(this)}>
                        -
                    </div>
                </div>
                    <div className="copies-des">
                        {this.props.t('copies')}
                    </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.eventReducer,
        camera: state.cameraReducer
    }
}

const CopiesWithRedux = connect(mapStateToProps)(Copies);
const CopiesWithT = withTranslation()(CopiesWithRedux);

export {CopiesWithT as Copies}
import { Vector3, Box3, Mesh, TextureLoader, ClampToEdgeWrapping, LinearFilter, PlaneGeometry, MeshBasicMaterial } from 'three';
import * as Debug from 'debug';
var debug = Debug('camera:Sticker');

var Sticker = function () {
  function Sticker(data, renderOrder, trashPosition) {
    this._inTrashScale = 0.8;
    this._hasScaleChange = false;
    this._prevScale = new Vector3();
    debug('Add sticker %o', data);
    this._data = data;
    this._trashPosition = trashPosition;
    this._renderOrder = renderOrder;

    this._initTexture();

    this._initMaterial();

    this._initGeometry();

    this._initMesh();

    this._onMove();

    this._onMoveEnd();
  }

  Sticker.prototype._initTexture = function () {
    this._texture = new TextureLoader().load(this._data.url);
    this._texture.wrapS = ClampToEdgeWrapping;
    this._texture.wrapT = ClampToEdgeWrapping;
    this._texture.minFilter = LinearFilter;
    this._texture.magFilter = LinearFilter;
  };

  Sticker.prototype._initGeometry = function () {
    this._geometry = new PlaneGeometry(this._data.width, this._data.height);
  };

  Sticker.prototype._initMaterial = function () {
    this._material = new MeshBasicMaterial({
      map: this._texture,
      transparent: true
    });
  };

  Sticker.prototype._initMesh = function () {
    this._mesh = new Mesh(this._geometry, this._material);
    this._mesh.renderOrder = this._renderOrder;
    debug('Sticker render order: %o', this._mesh.renderOrder);
  };

  Sticker.prototype._isTrashCollision = function () {
    var stickerPos = new Box3().setFromObject(this._mesh);
    return stickerPos.intersectsBox(this._trashPosition);
  };

  Sticker.prototype._onMove = function () {
    var _this = this;

    this._mesh.addEventListener('move', function (event) {
      if (_this._isTrashCollision()) {
        if (!_this._hasScaleChange) {
          _this._hasScaleChange = true;
          var _a = _this._mesh.scale,
              x = _a.x,
              y = _a.y,
              z = _a.z;

          _this._prevScale.set(x, y, z);

          _this._mesh.scale.x = _this._inTrashScale;
          _this._mesh.scale.y = _this._inTrashScale;

          _this._mesh.updateMatrix();
        }
      } else if (_this._hasScaleChange) {
        _this._hasScaleChange = false;
        var _b = _this._prevScale,
            x = _b.x,
            y = _b.y,
            z = _b.z;

        _this._mesh.scale.set(x, y, z);

        _this._mesh.updateMatrix();
      }
    });
  };

  Sticker.prototype._onMoveEnd = function () {
    var _this = this;

    this._mesh.addEventListener('moveEnd', function (event) {
      if (_this._isTrashCollision()) {
        return _this.delete();
      }
    });
  };

  Sticker.prototype.getMesh = function () {
    return this._mesh;
  };

  Sticker.prototype.delete = function () {
    this._texture.dispose();

    this._material.dispose();

    this._geometry.dispose();

    var parent = this._mesh.parent;
    if (parent.type === "Scene") parent.remove(this._mesh);
  };

  return Sticker;
}();

export { Sticker };
export var TransformObject;

(function (TransformObject) {
  TransformObject[TransformObject["Camera"] = 0] = "Camera";
  TransformObject[TransformObject["Object"] = 1] = "Object";
})(TransformObject || (TransformObject = {}));

export var SelfieCamera;

(function (SelfieCamera) {
  SelfieCamera["User"] = "user";
  SelfieCamera["Environment"] = "environment";
})(SelfieCamera || (SelfieCamera = {}));

export var PostProcessingState;

(function (PostProcessingState) {
  PostProcessingState[PostProcessingState["Video"] = 0] = "Video";
  PostProcessingState[PostProcessingState["Image"] = 1] = "Image";
})(PostProcessingState || (PostProcessingState = {}));
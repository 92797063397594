import React, { Component } from 'react';
import stickersIcon from './images/sticker-icon.png';
import './sticker.scss';

class Sticker extends Component {
    render() {
        return (
            <div id="camera-Sticker-btn" onClick={this.props.onClick}>
                <img src={stickersIcon} alt="Sticker" />
            </div>
        )
    }
}

export {Sticker}
import React, { Component } from 'react';
import closeIcon from './images/close-icon.png';
import './popupScreen.scss';

class PopupScreen extends Component {
    render() {
        return (
            <div className="popup-screen">
                <div className="popup-area">
                    <div className={`popup-close ${this.props.showCloseButton ? 'show' : 'hide'}`} onClick={this.props.onClose}>
                        <img src={closeIcon} alt="Close"/>
                    </div>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export {PopupScreen}
import React, { Component } from 'react';
import './filter.scss';
import filterIcon from './images/filter-icon.png';

class Filter extends Component {
    render() {
        return (
            <div id="camera-filter-btn" onClick={this.props.onClick}>
                <img src={filterIcon} alt="Filter" />
            </div>
        )
    }
}

export {Filter}
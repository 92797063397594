import React, { Component } from 'react';
import {MainScreen} from '../UiElements';
import {withTranslation} from 'react-i18next';
import {EventActions} from '../../actions';
import notFoundIcon from './images/not-found-icon.png';
import './page404.scss';

class Page404 extends Component {
    componentWillMount() {
        EventActions.pageTitle(this.props.t('page404Title'));
    }

    render() {
        const {t} = this.props;
        return (
            <MainScreen>
                <div id="page404">
                    <img src={notFoundIcon} alt="404" className="page404-icon"/>
                    <div className="page404-title">
                        {t('page404Title')}
                    </div>
                </div>
            </MainScreen>
        );
    }
}

const Page404WithT = withTranslation()(Page404);
export {Page404WithT as Page404};
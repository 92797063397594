export const cameraConstants = {
    SET_COPIES: 'SET_COPIES',
    INC_COPIES: 'INC_COPIES',
    DEC_COPIES: 'DEC_COPIES',

    UPLOAD_IMAGE_SUCCESS: 'UPLOAD_IMAGE_SUCCESS',
    UPLOAD_IMAGE_BLOCKED: 'UPLOAD_IMAGE_BLOCKED',
    UPLOAD_IMAGE_REMOVE_BLOCKED: 'UPLOAD_IMAGE_REMOVE_BLOCKED',

    PLAY: 'PLAY',
    FLIP: 'FLIP',
    PIC: 'PIC'
}
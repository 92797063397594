import React, { Component } from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {PopupScreen} from '../UiElements';
import {CameraActions} from '../../actions';
import onprogressIcon from './images/onprogress-icon.png';
import onsuccessIcon from './images/onsuccess-icon.png';
import cameraIcon from './images/camera-icon.png';
import {uploadConstants} from '../../constants';

import './upload.scss';

class Upload extends Component {
    onClose() {
        this.props.dispatch({type: uploadConstants.RESET_UPLOAD});
        this.props.dispatch(CameraActions.resetCopies());
    }
    
    nextImage() {
        this.onClose();
        this.props.dispatch(CameraActions.restart());
    }

    render() {
        const {t, upload, event} = this.props;
        const {uploadInProcess, successUpload, uploadError} = upload;

        if(!uploadError && !successUpload && !uploadInProcess) return null;

        return (
            <div id="upload-popup">
                <PopupScreen showCloseButton={successUpload || uploadError} onClose={this.onClose.bind(this)}>
                    {
                        uploadInProcess && 
                        <div className="upload-progress-wrapper">
                            <div className="upload-progress">
                                <img className="onprogress-icon" src={onprogressIcon} alt="On Progress" />
                                <div className="onprogress-text">
                                    {this.props.upload.process || 0}%
                                </div>
                            </div>
                            <div className="upload-description">
                                {t('onUploadImage')}
                            </div>
                        </div>
                    }
                    {
                        successUpload &&
                        <div className="upload-success-wrapper">
                            <img className="onsuccess-icon" src={onsuccessIcon} alt="Success"/>
                            <div className="onsuccess-description">
                                <strong>
                                    {t('onSuccessUpload')}
                                </strong>
                                {t('onSuccessUploadSecondLine')}
                                
                                <div className="onsuccess-btn" onClick={this.nextImage.bind(this)}>
                                    {t('onSuccessButton')}
                                </div>
                            </div>
                            <div className={`onsuccess-image-counter ${event.maxImages && event.maxImages.user <= 3 ? 'show': ''}`}>
                                <img className="image-counter-icon" src={cameraIcon} alt="Camera"/>
                                <div className="image-counter-text">
                                    {t('imageCounter', {number: event.maxImages.user})}
                                </div>
                            </div>
                        </div>
                    }
                </PopupScreen>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.eventReducer,
        upload: state.uploadReducer
    }
}

const UploadWithRedux = connect(mapStateToProps)(Upload);
const UploadWithT = withTranslation()(UploadWithRedux);

export {UploadWithT as Upload};
import React from 'react';
import {render} from 'react-dom';
import {App} from './components/App'
import {Provider} from 'react-redux';
import * as serviceWorker from './serviceWorker';
import store from './store';
import {ErrorBoundary} from './utils';
import './i18n';

const debug = require('debug');

// disable debug on production (disallow anyone to investigate our app)
if(process.env.NODE_ENV === 'production') debug.disable();

render(
    <ErrorBoundary>
        <Provider store={store}>
            <App />
        </Provider>
    </ErrorBoundary>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();

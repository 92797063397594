var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import { TextureLoader, ClampToEdgeWrapping, LinearFilter, PlaneGeometry, Mesh, Box3 } from 'three';
import { TransformObject } from '../utils';
import { Layer } from './';
import { Sticker } from './objects';
import * as Debug from 'debug';
var debug = Debug('camera:StickersLayer');

var StickersLayer = function (_super) {
  __extends(StickersLayer, _super);

  function StickersLayer(dimensions, controls, trash) {
    var _this = _super.call(this, dimensions) || this;

    _this._trashIcon = trash;
    _this._controls = controls;

    _this.initCamera();

    _this.initTexture();

    _this.initMaterial(true);

    _this.initGeometry();

    _this.initMesh();

    _this.initScene();

    _this._initTrashPositionBox();

    _this._initControls();

    return _this;
  }

  StickersLayer.prototype._initControls = function () {
    this._controls.addLayer({
      camera: this.camera,
      scene: this.scene,
      priority: StickersLayer._layerPriority,
      name: StickersLayer._layerName,
      transformObject: TransformObject.Object,
      zoom: {
        min: 0,
        max: Infinity,
        start: 1
      },
      enableZoom: true,
      enableRotate: true,
      enableTranslation: true
    });
  };

  StickersLayer.prototype._getMaxRenderOrder = function () {
    var max = 0;

    for (var i = 0; i < this.scene.children.length; i++) {
      max = Math.max(max, this.scene.children[i].renderOrder);
    }

    return max;
  };

  StickersLayer.prototype._initTrashPositionBox = function () {
    this._trashPosition = new Box3().setFromObject(this.mesh);
    debug('Trash position: %o', this._trashPosition);
  };

  StickersLayer.prototype.initTexture = function () {
    this.texture = new TextureLoader().load(this._trashIcon.sticker.url);
    this.texture.wrapS = ClampToEdgeWrapping;
    this.texture.wrapT = ClampToEdgeWrapping;
    this.texture.minFilter = LinearFilter;
    this.texture.magFilter = LinearFilter;
  };

  StickersLayer.prototype.initGeometry = function () {
    this.geometry = new PlaneGeometry(this._trashIcon.sticker.width, this._trashIcon.sticker.height);
  };

  StickersLayer.prototype.initMesh = function () {
    this.mesh = new Mesh(this.geometry, this.material);
    this.mesh.renderOrder = -Infinity;
    this.mesh.userData.uncontrollabel = true;
    this.mesh.position.x = this._trashIcon.position.x;
    this.mesh.position.y = this._trashIcon.position.y;
  };

  StickersLayer.prototype.addSticker = function (data) {
    var sticker = new Sticker(data, this._getMaxRenderOrder(), this._trashPosition);
    this.scene.add(sticker.getMesh());
  };

  StickersLayer.prototype.clearAllStickers = function () {
    var minChildren = this._trashIcon ? 1 : 0;

    while (this.scene.children.length > minChildren) {
      var sticker = this.scene.children[minChildren];
      if (sticker.material && sticker.material.map) sticker.material.map.dispose();
      if (sticker.material) sticker.material.dispose();
      if (sticker.geometry) sticker.geometry.dispose();
      this.scene.remove(sticker);
    }
  };

  StickersLayer._layerName = 'stickers';
  StickersLayer._layerPriority = 0;
  return StickersLayer;
}(Layer);

export { StickersLayer };
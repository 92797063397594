import React, { Component } from 'react';
import {withTranslation} from 'react-i18next';
import {MainScreen} from '../../UiElements';
import {EventActions} from '../../../actions';
import './eventWaitingPage.scss';
import waitingIcon from './images/event-waiting-icon.png';

class EventWaitingPage extends Component {
    componentWillMount() {
        EventActions.pageTitle(this.props.t('eventWaiting'));
    }

    render() {
        const {t} = this.props;

        return (
            <MainScreen id="event-waiting">
                <div className="waiting-wrapper">
                    <img className="waiting-image" src={waitingIcon} alt="waiting"/>
                    <div className="waiting-title">
                        {t('eventWaiting')}
                    </div>
                </div>
            </MainScreen>
        );
    }
}

const EventWaitingPageWithT = withTranslation()(EventWaitingPage);
export {EventWaitingPageWithT as EventWaitingPage}